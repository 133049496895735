<template>
  <div>
    <VxAlert prominent type="info">
      <v-row align="center">
        <v-col class="grow">
          {{ $t("onboarding.allSettingsAlert.text") }}
        </v-col>
        <v-col class="shrink">
          <VxBtn :to="{ name: 'onboarding.welcome' }">
            {{ $t("onboarding.allSettingsAlert.buttonText") }}
          </VxBtn>
        </v-col>
      </v-row>
    </VxAlert>
    <v-list class="py-0">
      <template v-for="(item, idx) in items">
        <v-list-item
          :key="item.title"
          exact
          :to="item.route"
          :href="item.href"
          :target="item.target"
        >
          <v-list-item-icon>
            <v-icon size="24">{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon>$right</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-divider v-if="idx < items.length - 1" :key="idx" inset></v-divider>
      </template>
    </v-list>
  </div>
</template>

<script>
import { VxAlert, VxBtn } from "@/core-ui";

export default {
  name: "SettingsView",
  components: { VxAlert, VxBtn },
  props: {
    storeId: {
      type: [Number, String],
      default: undefined,
    },
  },
  computed: {
    items() {
      return [
        {
          icon: "$devices",
          title: "Device",
          route: {
            name: "settings.device",
            params: { storeId: this.storeId },
          },
        },
        {
          icon: "$raiBilling",
          title: "Billing",
          href: `${process.env.VUE_APP_RAI_DOMAIN}/billing/customer-portal?store_id=${this.storeId}`,
          target: "_blank",
        },
        {
          icon: "$store",
          title: "Store",
          route: {
            name: "settings.store",
            params: { storeId: this.storeId },
          },
        },
        {
          icon: "$autoFix",
          title: "Automation",
          route: {
            name: "settings.automation",
            params: { storeId: this.storeId },
          },
        },
        {
          icon: "$raiDaybook",
          title: "Daybook",
          route: {
            name: "daybook.setup",
            params: { storeId: this.storeId },
          },
        },
        {
          icon: "$raiBackstock",
          title: "Backstock",
          route: {
            name: "backstock.setup",
            params: { storeId: this.storeId },
          },
        },
        {
          icon: "$raiBuys",
          title: "Buys",
          route: {
            name: "buy.setup",
            params: { storeId: this.storeId },
          },
        },
        {
          icon: "$raiBounceback",
          title: "Bounceback",
          route: {
            name: "bounceback.setup",
            params: { storeId: this.storeId },
          },
        },
        {
          icon: "$goal",
          title: "Goals",
          route: {
            name: "goal.setup",
            params: { storeId: this.storeId },
          },
        },
        {
          icon: "$raiReports",
          title: "Reports",
          route: {
            name: "reports.setup",
            params: { storeId: this.storeId },
          },
        },
        {
          icon: "$raiTexting",
          title: "Texting",
          route: {
            name: "texting.setup",
            params: { storeId: this.storeId },
          },
        },
      ];
    },
  },
};
</script>
